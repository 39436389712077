*{
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.project-card {
    border-radius: 10px;
    box-shadow: 0 0 10px #e0e0e0;
    cursor: pointer;
    margin-bottom: 20px;
}

.content {
    height: 150px;
    overflow: hidden;
    padding: 20px;
}
.project-tech {
    border-top: 2px solid #f1f1f1;
    padding: 10px;
}
.project-tech img {
    cursor: pointer;
    height: 25px;
    margin-right: 10px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    padding: 0.25rem;
}